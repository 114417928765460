import axios from 'axios';

export const customerOrdersTotal = async (customerToken) => {
  try {
    const response = await axios.post(
      `https://api.gogemini.io/graphql/538a6a42-7495-4e70-89de-abf51d36cfc1`,
      {
        query: `
          query customerOrders {
            customer {
              orders {
                total_count
              }
            }
          }
        `,
      },
      {
        headers: {
          Authorization: `Bearer ${customerToken}`,
        },
      }
    );

    return response?.data?.data?.customer?.orders?.total_count || 0;
  } catch (error) {
    console.error('Error fetching customer orders total:', error);
    return 0;
  }
};
